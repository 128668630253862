import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import Master from "./components/master";
import ScrollToTop from "./components/scrollToTop";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from "@material-ui/core/styles";
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      light: "#009B90",
      main: "#FFFFFF",
      dark: "#1B1B1B",
      contrastText: "#fff",
      grey: "#1B1B1B",
      lightGrey: "#303030"
    },
    secondary: {
      main: "#009B90"
    },
    text: {
      primary: "#1B1B1B",
      secondary: "#1B1B1B"
    }
  },
  typography: {
    fontFamily: "GothamRounded",
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
  },
  overrides: {
    MuiButton: {
      text: {
        textTransform: "none",
        color: "#1B1B1B",
        background: "#549F83"
      },
      label: {
        color: "#FFFFFF"
      },
      "root": {
        "&:hover": {
          boxShadow: "5px 5px 2px 0px rgba(0,0,0,0.3)",
          backgroundColor: "#549F83"
        },
        "&:disabled": {
          backgroundColor: "#666666 !important"
        },
      },
      "contained": {
        backgroundColor: "#549F83",
        "&:hover": {
          backgroundColor: "#549F83"
        }
      }
    },
    MuiFormLabel: {
      "root": {
        color: "#1B1B1B",
        fontSize: 24,
        "&:disabled": {
          color: "#1B1B1B"
        }
      }
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#1B1B1B"
        }
      }
    },
    MuiInputBase: {
      "root": {
        color: "#1B1B1B"
      },
      "inputMultiline": {
        lineHeight: "30px"
      }
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "1px solid #009B90"
        },
        "&:before": {
          borderBottom: "1px solid #1B1B1B"
        }
      }
    },
    MuiOutlinedInput: {
      "root": {
        "&$focused": {
          "& $notchedOutline": {
            borderColor: "#707070"
          }
        }
      }
    },
    MuiPaper: {
      "root": {
        backgroundColor: "#E8E8E8",
        padding: "20px"
      },
      "rounded": {
        borderRadius: "7px !important"
      }
    },
    MuiTab: {
      "root": {
        textTransform: "capitalize"
      }
    },
    MuiTable: {
      "root": {
        tableLayout: "fixed"
      }
    },
    MuiTableCell: {
      "head": {
        fontWeight: 600,
      }
    },
    MuiStepIcon: {
      "active": {
        color: "#009B90 !important"
      },
      "completed": {
        color: "#009B90 !important"
      }
    },
    MuiSvgIcon: {
      "root": {
        color: "black"
      }
    },
    MuiMenuItem: {
      "root": {
        "&:hover": {
          backgroundColor: "#009B90"
        }
      }
    },
  },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <HashRouter>
          <ScrollToTop>
            <Master />
          </ScrollToTop>
        </HashRouter>
      </MuiThemeProvider>
    );;
  }
}

export default App;